<template>
    <div class="contents">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between">
                        <div class="d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">
                                    List Device
                                </h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <a v-on:click="toggleModal('new_device')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-plus fs-16"></i>
                                Tambah Device
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="deviceQuota == -1" class="alert alert-success" role="alert">
                <div class="alert-content">
                    <p>Batas device maksimal anda adalah <b>Unlimited</b> device</p>
                </div>
            </div>
            <div v-else-if="deviceQuota == 0" class="alert alert-danger" role="alert">
                <div class="alert-content">
                    <p>Batas device maksimal anda adalah <b>{{ deviceQuota }}</b> device</p>
                </div>
            </div>
            <div v-else class="alert alert-primary" role="alert">
                <div class="alert-content">
                    <p>Batas device maksimal anda adalah <b>{{ deviceQuota }}</b> device</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <GroupTable />
                    </div>
                </div>
            </div>
            <modal name="new_device" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDevice />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import GroupTable from "@/components/tables/Device.vue";
    import AddDevice from "@/components/forms/device/Add.vue";

    export default {
        components: {
            GroupTable,
            AddDevice,
        },
        data() {
            return {
                group_dropdown: [],
                exportJson: [],
                deviceQuota: 0
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);

            this.deviceQuota = this.$store.state.auth.user.max_device
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            }
        },
    };
</script>
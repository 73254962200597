<template>
    <div>
        <div class="contents">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Detail Device</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h5> Detail Device </h5>
                            <br>
                            <label> Nama Device </label>
                            <div class="form-group mb-20">
                                <input type="text" class="form-control" placeholder="Nama device" v-model="form.name"
                                    required>
                            </div>
                            <button type="button" v-on:click="updateDevice"
                                class="btn btn-info btn-default btn-squared text-capitalize btn-block">
                                simpan
                            </button>
                            <hr style="margin-top: 10px;margin-bottom: 10px;">
                            <label> Server ID </label>
                            <div class="form-group mb-20">
                                <input type="text" class="form-control" v-model="form.server_id" readonly>
                            </div>
                            <label> API Key </label>
                            <div class="form-group mb-20">
                                <input type="text" class="form-control" v-model="form.device_key" readonly>
                            </div>
                            <a class="btn btn-success btn-block" v-on:click="regenerateApiKey">Generate New API Key</a>
                            <!-- <a v-if="form.multidevice == '0'" class="btn btn-danger  btn-block">Pindah Multidevice</a>
                            <a v-if="form.multidevice == '1'" class="btn btn-danger  btn-block">Pindah Legacy
                                (Biasa)</a> -->
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h5> Log Device </h5>
                            <br>
                            <textarea v-model="form.device_log" id="textarea" cols="30" rows="20" class="form-control"
                                readonly>
                            </textarea>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h5> Setting Jam Kerja </h5>
                            <br>
                            <div v-if="form.jam_kerja_is_active == '1'" class="jam_kerja_container">

                                <label for="">Pesan diluar jam kerja</label>
                                <br>
                                <CustomTextArea v-model="form.jam_kerja_message"></CustomTextArea>
                                <br>
                                <label for="">Senin</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.senin.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.senin.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Selasa</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.selasa.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.selasa.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Rabu</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.rabu.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.rabu.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Kamis</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.kamis.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.kamis.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Jumat</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.jumat.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.jumat.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Sabtu</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.sabtu.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.sabtu.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <label for="">Minggu</label>
                                <br>
                                <div class="row">
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_buka"
                                            v-model="jam_kerja.minggu.buka" placebolder="Buka" value="">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="time" class="form-control timepicker_tutup"
                                            v-model="jam_kerja.minggu.tutup" placebolder="Tutup" value="">
                                    </div>
                                </div>
                                <br>
                                <button class="btn btn-success btn-block" type="button"
                                    v-on:click="saveJamKerja">Simpan</button>
                                <a class="btn btn-danger btn-block" v-on:click="setJamKerja(false)">Nonaktifkan</a>
                            </div>
                            <a v-else v-on:click="setJamKerja(true)"
                                class="btn btn-success btn-block ">Aktifkan</a>

                        </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                        <div class="global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h4> Kamus </h4>
                            <br>
                            <div class="row">
                                <table class='table'>
                                    <thead>
                                        <tr>
                                            <td>Penyebab</td>
                                            <td>Keterangan</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>bad_session</td>
                                            <td>Mendapatkan error 500 saat memanggil query -- terjadi kesalahan sangat
                                                fatal</td>
                                        </tr>
                                        <tr>
                                            <td>close</td>
                                            <td>Koneksi ditutup oleh client atau whatsapp</td>
                                        </tr>
                                        <tr>
                                            <td>intentional</td>
                                            <td>Koneksi ditutup secara sengaja</td>
                                        </tr>
                                        <tr>
                                            <td>invalid_session</td>
                                            <td>Session telah kadaluwarsa -- telah terjadi logout</td>
                                        </tr>
                                        <tr>
                                            <td>lost</td>
                                            <td>Koneksi dengan whatsapp hilang</td>
                                        </tr>
                                        <tr>
                                            <td>replaced</td>
                                            <td>Whatsapp web dibuka dilain tempat, menyebabkan session kadaluwarsa</td>
                                        </tr>
                                        <tr>
                                            <td>timed out</td>
                                            <td>Waktu koneksi habis</td>
                                        </tr>
                                        <tr>
                                            <td>unknown</td>
                                            <td>Tidak tau, bisa jadi karena logout</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    // import CustomTextArea from "@/components/module/CustomTextarea.vue";

    export default {
        components: {
            // CustomTextArea
        },
        data() {
            return {
                exportJson: [],
                form: {
                    name: "",
                    server_id: "",
                    device_id: "",
                    jam_kerja_is_active: "0",
                    jam_kerja_message: "",
                    device_log: "",
                },
                jam_kerja: {
                    senin: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    selasa: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    rabu: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    kamis: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    jumat: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    sabtu: {
                        buka: "00:00",
                        tutup: "00:00"
                    },
                    minggu: {
                        buka: "00:00",
                        tutup: "00:00"
                    }
                },
                logs: [],
                device_id: ""
            };
        },
        mounted() {
            this.device_id = this.$route.query.id
            this.loadData()
        },
        methods: {
            async regenerateApiKey() {
                if (!confirm("Yakin ingin merubah API Key ?")) return

                try {
                    let res = await this.$store.dispatch("device_regenerate", {
                        id: this.device_id
                    })
                    this.form.device_key = res.data.data.device.device_key
                    this.$toast.success(res.data.message)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async loadData() {
                try {
                    let detail = await this.$store.dispatch("device_detail", {
                        id: this.device_id
                    })
                    // let log = await this.$store.dispatch("device_log", {
                    //     id: this.device_id
                    // })
                    //this.logs = log.data.data
                    this.form = detail.data.data.device
                    if(this.form.jam_kerja){
                        this.jam_kerja = JSON.parse(this.form.jam_kerja)
                    }
                    //this.formatLog()
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                    this.$router.push("/device")
                }
            },
            async updateDevice() {
                try {
                    let response = await this.$store.dispatch('device_update', {
                        id: this.device_id,
                        name: this.form.name
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            async saveJamKerja() {
                try {
                    this.form.jam_kerja_is_active = 1
                    let response = await this.$store.dispatch('device_set_jam_kerja', {
                        id: this.form.id,
                        jam_kerja: this.jam_kerja,
                        jam_kerja_is_active: 1,
                        jam_kerja_message: this.form.jam_kerja_message
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
            formatLog() {
                this.form.device_log = ""
                for (let i = 0; i < this.logs.length; i++) {
                    this.form.device_log += moment().utc(this.logs[i].created_at).format("YYYY-MM-DD HH:mm:ss") +
                        " | " + this.logs[i].logs
                    this.form.device_log += "\n\n"
                }

            },
            async setJamKerja(bool) {
                if (bool) {
                    this.form.jam_kerja_is_active = "1"
                } else {
                    if (!confirm("Yakin ingin menonaktifkan jam kerja ?")) return

                    this.form.jam_kerja_is_active = "0"
                    try {
                        let response = await this.$store.dispatch('device_set_jam_kerja', {
                            id: this.form.id,
                            jam_kerja: this.jam_kerja,
                            jam_kerja_is_active: 0,
                            jam_kerja_message: this.form.jam_kerja_message
                        })
                        this.$toast.success(response.data.message);
                    } catch (err) {
                        this.$toast.error(err.response.data.message);
                    }
                }
            },
        },
    };
</script>